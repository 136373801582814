/* eslint-disable react-hooks/exhaustive-deps */
import {
	Skeleton,
	useDisclosure,
	VStack,
	Center,
	Button,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	Spinner,
	useTheme,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	ModalFooter,
	Divider,
	Drawer,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
} from '@chakra-ui/react'
import { sortBy, unionBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import {
	useLoadAllFormList,
	useLoadSubmittedFormList,
	useCafCrfActivityCheck,
	useRegenerateCafView,
} from '../../../../hooks/form.hooks'
import {
	useArchiveDraftForm,
	useAddTimelineUpdate,
	useLoadAccessSelectActivity,
} from '../../../../hooks/activity.hooks'
import FormListingItem from './form-listing-item'
import { useSharedContext } from '../../../../context/shared.context'
import CafCrfModalView from '../caf-crf-view/CafCrfModalView'
import { useToast } from '@chakra-ui/react'
import { sectionDetailedTrack } from '../../../repository/repo.utils'
import { GA_CATEGORY_DIALOG_BOX } from './../../../repository/repo.utils'
import { useLanguageContext } from '../../../../context/locale.context'
import { useAuthContext } from '../../../../context/auth.context'
import { sharedConst } from '../../../../utils/action.constant'

const FormListing = ({
	status,
	activity,
	onFormSelection,
	isFormEnable,
	openDelegateFormPopup,
	isOpen,
	isCurrentStatus,
	isFromWhatNext = false,
	participants = [],
}) => {
	// const { isOpen, onToggle } = useDisclosure();
	// TODO: use only if got clearance on the submitted forms
	const toast = useToast({
		duration: 1000,
	})
	const {
		state: { authData },
	} = useAuthContext()

	const {
		state: { locale },
	} = useLanguageContext()
	const { mutate: mutateLoadAllForms, isLoading } = useLoadAllFormList()
	const cancelRef = React.useRef()
	const { mutate: mutateSubmittedForm, isLoading: loadingSubmittedForm } =
		useLoadSubmittedFormList()
	const { mutate: mutateArchiveDraftForm } = useArchiveDraftForm()
	const { mutate: mutateRegenerateCaf } = useRegenerateCafView()
	const { mutate: mutateTimeline } = useAddTimelineUpdate()
	const { mutate: loadAccessSelectActivity } = useLoadAccessSelectActivity()

	const [loadSuperAdmins, setloadSuperAdmins] = useState([])
	const [mappedForms, setMappedForms] = useState([])
	const [submittedForms, setSubmittedForms] = useState([])
	const [draftData, setDraftData] = useState(null)
	const [romsActivity, setRomsActivity] = useState(null)
	const [isRomsOpen, setOpenRomsView] = useState(false)
	const [isRegenerate, setIsRegenerate] = useState(false)
	const [originFormId, setOriginFormId] = useState(0)
	const [selectedForm, setSelectedForm] = useState(null)
	const [viewName, setViewName] = useState('')
	const {
		isOpen: isDraftOpen,
		onOpen: onDraftOpen,
		onClose: onDraftClose,
	} = useDisclosure()
	const {
		isOpen: isOpenNoAccess,
		onOpen: onOpenNoAccess,
		onClose: onCloseNoAccess,
	} = useDisclosure()
	const chakraTheme = useTheme()
	const {
		dispatch,
		state: { isShowLoader, random },
	} = useSharedContext()
	const loadAllForms = () => {
		const {
			activity_id,
			activity_type_id,
			parent_status_id,
			activity_status_id,
			activity_type_category_id,
		} = activity
		mutateLoadAllForms(
			{
				statusBasedForms: {
					activity_status_id: !!parent_status_id
						? parent_status_id
						: activity_status_id,
					workflow_activity_id: activity.activity_id,
					activity_type_category_id,
					flag: 0,
				},
				submittedForms: {
					flag: 1,
					form_id: 0, // form id should be 0 to load all forms
					activity_id,
					activity_type_id,
					activity_type_category_id,
				},
			},
			{
				onSuccess: async data => {
					data = sortBy(data, ['isOrder']).reverse()
					setMappedForms(data)
					let origin_form_id = (data || [])
						.filter(f => f.form_flag_workflow_origin === 1)
						.reduce((_, s) => s.form_id, 0)
					setOriginFormId(origin_form_id)
				},
				onError: async err => {
					console.log('Error goes here', err)
					toast({
						title: 'Error while loading forms list',
						status: 'error',
					})
				},
			}
		)
	}

	useEffect(() => {
		if (!!activity) {
			setMappedForms([])
			loadAllForms()
		}
	}, [isShowLoader.toString(), random, activity?.activity_status_id])

	useEffect(() => {
		if (!!activity) {
			loadAccessSelectActivity(
				{
					activity_type_id: activity?.activity_type_id,
					flag: 0,
				},
				{
					onSuccess: async data => {
						setloadSuperAdmins(data)
					},
					onError: async err => {
						console.log('err', err)
					},
				}
			)
		}
	}, [activity])

	// useEffect(() => {
	//   console.log(!!activity, !!isOpen, '!!activity && !!isOpen');
	//   if (!!activity && !!isOpen) {
	//     loadAllForms('useeffect');
	//   }
	// }, [activity, isOpen]);

	const onDraftDelete = form => {
		setDraftData(form)
		onDraftOpen()
	}

	const onDraftDeleteConfirm = () => {
		onDraftClose()
		const { form_transaction_id, form_id } = draftData
		mutateArchiveDraftForm(
			{
				form_id: form_id,
				form_transaction_id: form_transaction_id,
			},
			{
				onSuccess: async data => {
					console.log('archive draft data', data)
					loadAllForms()
				},
				onError: async err => {
					console.log('Error', err)
				},
			}
		)
	}

	const onOpenRomsView = form => {
		let romsConfig = !!form.form_config_roms
			? JSON.parse(form.form_config_roms)
			: null
		let romsActivity = !!romsConfig
			? !!romsConfig['form_config_roms']
				? romsConfig['form_config_roms'][activity.activity_type_id]
				: romsConfig[activity.activity_type_id]
			: null
		console.log('roms', romsActivity)
		console.log('form', form)
		setOpenRomsView(true)
		setSelectedForm(form)
		setRomsActivity(romsActivity)
	}

	const onRegerateAlert = name => {
		setIsRegenerate(true)
		setViewName(name)
	}

	const onConfirmRegenerate = () => {
		const {
			activity_id,
			activity_type_id,
			parent_status_id,
			activity_status_id,
			activity_type_category_id,
		} = activity
		const { operating_asset_first_name } = authData
		mutateRegenerateCaf(
			{
				asset_message_counter: 0,
				message_unique_id: 1548755187343,
				workflow_activity_id: activity.activity_id,
				form_id: originFormId,
				non_dedicated_file: 1,
			},
			{
				onSuccess: async res => {
					setIsRegenerate(false)
					if (res.status === 200) {
						let payloadTimeline = {
							content: {
								content: `${viewName} Regenerate has been submitted. Please check after sometime`,
							},
						}
						mutateTimeline(
							{
								activity_stream_type_id: 509,
								timeline_stream_type_id: 509,
								operating_asset_first_name,
								activity_id,
								activity_type_category_id,
								activity_type_id,
								// data_entity_inline: JSON.stringify(payloadTimeline),
								activity_timeline_collection: JSON.stringify(payloadTimeline),
							},
							{
								onSuccess: async data => {
									dispatch({
										type: sharedConst.REFRESH_TIMELINE,
									})
								},
							}
						)
					}
				},
				onError: async err => console.log(err),
			}
		)
	}

	const onClose = () => {
		setOpenRomsView(false)
		setSelectedForm(null)
		setRomsActivity(null)
		setIsRegenerate(false)
	}

	const isSuperAdmin = () => {
		const superAdmin = authData.asset_flag_super_admin === 1 ? true : false
		let isSuperAdminCheck
		let isUserHaveAccess
		if (!!loadSuperAdmins.length) {
			// isSuperAdminCheck = loadSuperAdmins.find(
			//   item => item.asset_type_id === authData.asset_type_id
			// )
			isUserHaveAccess = loadSuperAdmins.find(
				item => item.asset_id === authData.asset_id
			)
		}
		if (superAdmin || !!isSuperAdminCheck || !!isUserHaveAccess) {
			return true
		} else {
			return false
		}
	}

	let participant = participants.find(
		item => item.asset_id === authData.asset_id
	)

	const checkFormAccess = !!participant
		? participant.asset_flag_is_owner === 1
			? true
			: false
		: false

	if (isLoading || isShowLoader) {
		return (
			<Center p={2} my={1}>
				<Spinner
					thickness='4px'
					speed='0.65s'
					emptyColor={chakraTheme.colors.secondary}
					color={chakraTheme.colors.brand[800]}
					size='md'
				/>
			</Center>
		)
	}

	return (
		<>
			<AlertDialog
				isOpen={isDraftOpen}
				leastDestructiveRef={cancelRef}
				onClose={onDraftClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							{locale['Delete Draft Form']}
						</AlertDialogHeader>

						<AlertDialogBody>
							{locale["Are you sure? You can't undo this action afterwards."]}
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button
								ref={cancelRef}
								borderRadius='md'
								size='md'
								variant='outline'
								colorScheme={localStorage.getItem('color')}
								onClick={() => {
									sectionDetailedTrack({
										category: GA_CATEGORY_DIALOG_BOX,
										action: 'Form Listing',
										label: 'Cancel',
									})
									onDraftClose()
								}}
							>
								{locale['Cancel']}
							</Button>
							<Button
								borderRadius='md'
								size='md'
								variant='solid'
								colorScheme={localStorage.getItem('color')}
								bg={localStorage.getItem('color')}
								onClick={() => {
									sectionDetailedTrack({
										category: GA_CATEGORY_DIALOG_BOX,
										action: 'Form Listing',
										label: 'Delete',
									})
									onDraftDeleteConfirm()
								}}
								ml={3}
							>
								{locale['Delete']}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			<AlertDialog
				isOpen={isRegenerate}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							{['Regenerate View']}
						</AlertDialogHeader>

						<AlertDialogBody>
							{['Are you sure to regenerate view?']}
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button
								ref={cancelRef}
								borderRadius='md'
								size='md'
								variant='outline'
								colorScheme={localStorage.getItem('color')}
								onClick={() => {
									sectionDetailedTrack({
										category: GA_CATEGORY_DIALOG_BOX,
										action: 'Form Listing',
										label: 'Cancel',
									})
									onClose()
								}}
							>
								{locale['Cancel']}
							</Button>
							<Button
								borderRadius='md'
								size='md'
								variant='solid'
								colorScheme={localStorage.getItem('color')}
								bg={localStorage.getItem('color')}
								onClick={() => {
									sectionDetailedTrack({
										category: GA_CATEGORY_DIALOG_BOX,
										action: 'Form Listing',
										label: 'Regenerate',
									})
									onConfirmRegenerate()
								}}
								ml={3}
							>
								{['Regenerate']}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			<VStack w='full' p={2} my={2} maxH='full' overflowY='scroll'>
				{(unionBy(submittedForms, mappedForms, 'form_id') || [])
					.sort((s, t) => s.form_sequence_id - t.form_sequence_id)
					// .sort((s, t) => t.isSubmitted - s.isSubmitted)
					// .sort((s, t) => s.form_transaction_id - t.form_transaction_id) //commented sort by submitted form on 13-Oct
					.map(f => {
						return (
							<FormListingItem
								key={Math.random() * Date.now()}
								f={f}
								onFormSelection={onFormSelection}
								isFormEnable={isFormEnable}
								openDelegateFormPopup={openDelegateFormPopup}
								isCurrentStatus={isCurrentStatus}
								draftDelete={onDraftDelete}
								romsActivity={romsActivity}
								onOpenRomsView={onOpenRomsView}
								activity={activity}
								onRegerateAlert={onRegerateAlert}
								isSuperAdmin={isSuperAdmin()}
								isFromWhatNext={isFromWhatNext}
								onOpenNoAccess={onOpenNoAccess}
							/>
						)
					})}
			</VStack>
			<Drawer
				isOpen={isRomsOpen}
				placement='right'
				onClose={onClose}
				size={'xl'}
				// finalFocusRef={btnRef}
			>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton mr={4} mt={1} />
					{/* <DrawerHeader>{activity?.activity_title} </DrawerHeader> */}
					<DrawerBody>
						<CafCrfModalView
							activity={activity}
							formData={selectedForm}
							romsActivity={romsActivity}
							submittedForms={mappedForms}
						/>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
			{/* <Modal
        isOpen={isRomsOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent borderRadius="sm">
          <ModalHeader fontSize="lg" fontWeight="400">
            {activity?.activity_title}
          </ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody>
            <CafCrfModalView activity={romsActivity} formData={selectedForm} />
          </ModalBody>

        </ModalContent>
      </Modal> */}
			<AlertDialog
				isOpen={isOpenNoAccess}
				leastDestructiveRef={cancelRef}
				onClose={onCloseNoAccess}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							{'Form Access Denied'}
						</AlertDialogHeader>
						<AlertDialogBody>{'Status due date is expired'}</AlertDialogBody>
						<AlertDialogFooter>
							<Button
								ref={cancelRef}
								colorScheme={localStorage.getItem('color')}
								bg={localStorage.getItem('color')}
								onClick={() => {
									onCloseNoAccess()
								}}
							>
								{locale['Ok']}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	)
}

export default React.memo(FormListing)
