import React, { useState, useEffect } from 'react'
import {
	Divider,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	useDisclosure,
} from '@chakra-ui/react'
import StatusListContainer from './status-listing'
import FormListing from './form-listing'
import Card from '../../../components/card'
import DelegateFormPopup from './form-delegation'

const DataManagement = ({
	isOverviewOpen,
	participants,
	onFormSelection,
	isFormEnable,
	activity,
	onDelegateFormClose,
	isFromWN = false,
	isFromWhatNext = false,
	isFromTable = false,
}) => {
	const [selectedForm, setSelectedForm] = useState()
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [updatedActivity, setUpdatedActivity] = useState({})

	useEffect(() => {
		setUpdatedActivity(activity)
	}, [activity])

	const openDelegateFormPopup = form => {
		setSelectedForm(form)
		onOpen()
	}

	const closeDelegateFormPopup = () => {
		onDelegateFormClose()
		onClose()
	}

	return (
		<Card
			className={`data-management-card`}
			maxH={
				isOverviewOpen
					? 'calc(100vh - 300px)'
					: isFromWN
					? 'calc(100vh - 480px)'
					: isFromTable
					? ''
					: 'calc(100vh - 180px)'
			}
			minH={
				isOverviewOpen
					? 'calc(100vh - 300px)'
					: isFromWN
					? 'calc(100vh - 480px)'
					: 'calc(100vh - 180px)'
			}
			h={isFromTable ? '' : '100%'}
			w='100%'
			px={4}
			overflowY='scroll'
		>
			{/* <Text textAlign="center" fontSize="sm" color="green.300">
        Either you have to be Creator, Owner or Lead to have access to this
        section
      </Text> */}
			{/* <StatusListContainer
        participants={participants}
        onFormSelection={onFormSelection}
        isFormEnable={isFormEnable}
        openDelegateFormPopup={openDelegateFormPopup}
      /> */}
			<FormListing
				isOpen={true}
				isCurrentStatus={true}
				onFormSelection={onFormSelection}
				activity={updatedActivity}
				status={{}}
				isFormEnable={isFormEnable}
				openDelegateFormPopup={openDelegateFormPopup}
				isFromWhatNext={isFromWhatNext}
				participants={participants}
			/>
			<Modal
				className={`data-management-modal`}
				isCentered
				size='2xl'
				isOpen={isOpen}
				onClose={closeDelegateFormPopup}
			>
				<ModalOverlay className={`data-management-overlay`} />
				<ModalContent className={`data-management-modal-content`}>
					<ModalHeader>
						<Text>
							Assign Participant To Form{' '}
							{!!selectedForm ? selectedForm.form_name : null}
						</Text>
					</ModalHeader>
					<Divider />
					<ModalCloseButton className={`data-management-modal-close-btn`} />
					<ModalBody className={`data-management-modal-body`}>
						<DelegateFormPopup activity={updatedActivity} form={selectedForm} />
					</ModalBody>
				</ModalContent>
			</Modal>
		</Card>
	)
}

export default DataManagement
