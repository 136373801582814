/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  SimpleGrid,
  Spacer,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { MdDelete } from 'react-icons/md'
import Select from 'react-select'
import { useAuthContext } from '../../../../context/auth.context'
import {
  useAddTimelineUpdate,
  useInviteParticipant,
  useLoadActivityParticipant,
  useSearchOrgContacts,
} from '../../../../hooks/activity.hooks'
import {
  useAddFormParticipant,
  useRemoveFormParticipant,
} from '../../../../hooks/form.hooks'
import {
  getMessageUniqueId,
  sanitizeContent,
} from '../../../../utils/common.util'
import {
  formatDueDateForTimelineCurrDate,
  formatNoteUpdateDate,
} from '../../../../utils/date.utils'
import { sectionDetailedTrack } from '../../../repository/repo.utils'
import { GA_CATEGORY_DIALOG_BOX } from './../../../repository/repo.utils'

function DelegateFormPopup({ form, activity, refreshActivity }) {
  const {
    state: { authData },
  } = useAuthContext()

  const { isOpen: submittedView, onToggle: setSubmittedView } = useDisclosure()

  const { mutate: mutateLoadOrgContacts } = useSearchOrgContacts()
  const {
    mutate: mutateloadActivityParticipants,
  } = useLoadActivityParticipant()
  const { mutate: mutateAddFormParticipant } = useAddFormParticipant()
  const { mutate: mutateInviteParticipant } = useInviteParticipant()
  const { mutate: mutateRemoveFormParticipant } = useRemoveFormParticipant()
  const { mutate: mutateAddTimeLineUpdate } = useAddTimelineUpdate()

  const [participantList, setParticipantList] = useState([])
  const [contactList, setContactList] = useState([])
  const [contactValue, setContactValue] = useState({
    selectedUsers: [],
    comment: '',
  })

  const loadOrgContacts = useCallback(
    (search_string = '') => {
      mutateLoadOrgContacts(
        { search_string, isDelegate: true },
        {
          onSuccess: data => {
            const participantOptions =
              !!data && data.length > 0
                ? data
                    .filter(contact => {
                      if (participantList && participantList.length > 0) {
                        return !participantList.find(
                          extcontact =>
                            extcontact.asset_id === contact.contact_asset_id
                        )
                      } else {
                        return true
                      }
                    })
                    .map(contact => {
                      const { operating_asset_first_name, asset_id } = contact
                      return {
                        label: operating_asset_first_name,
                        value: asset_id,
                        data: contact,
                      }
                    })
                : []
            setContactList(participantOptions)
          },
        }
      )
    },
    [mutateLoadOrgContacts, setContactList, participantList]
  )

  const loadParticipants = useCallback(() => {
    mutateloadActivityParticipants(
      {
        activity_id: activity.activity_id,
      },
      {
        onSuccess: data => {
          const { activity_master_data } = activity
          if (!!activity_master_data) {
            const form_fill_request = JSON.parse(activity_master_data)
              .form_fill_request
            const existingParticipant = form_fill_request.filter(item => {
              return item.form_id === form.form_id
            })
            setParticipantList(existingParticipant)
          }
        },
      }
    )
  }, [
    mutateloadActivityParticipants,
    setParticipantList,
    activity,
    form.form_id,
  ])

  const buildTimelineEntryParams = (content, isDesignate = 0) => {
    let activityTimelineJson = {
      content: sanitizeContent(content),
      subject: 'Note - ' + formatNoteUpdateDate(),
      mail_body: sanitizeContent(content),
      attachments: [],
      activity_reference: [
        {
          activity_title: '',
          activity_id: '',
        },
      ],
      asset_reference: [],
      form_approval_field_reference: [],
      is_reply: 0,
    }
    return {
      activity_id: activity.activity_id,
      activity_type_category_id: activity.activity_type_category_id,
      activity_type_id: activity.activity_type_id,
      activity_parent_id: activity.parent_activity_id,
      activity_timeline_collection: JSON.stringify(activityTimelineJson),
      // data_entity_inline: JSON.stringify(activityTimelineJson),
      operating_asset_first_name: authData.operating_asset_first_name,
      isDesignate,
    }
  }

  const removeDesignation = participant => {
    const removeParticipantName = {
      label: participant.operating_asset_first_name,
    }
    const { activity_id } = activity
    const payload = {
      workflow_activity_id: activity_id,
      participant_collection: JSON.stringify([
        {
          form_id: participant.form_id,
          asset_id: participant.asset_id,
        },
      ]),
    }
    mutateRemoveFormParticipant(
      {
        payload,
      },
      {
        onSuccess: data => {
          if (data === 200) {
            updateTimelineEntry(removeParticipantName)
          }
        },
      }
    )
  }

  const onDebounceSearch = useCallback(
    debounce(e => {
      loadOrgContacts(e.target.value)
    }, 500),
    [loadOrgContacts]
  )

  const updateTimelineEntry = (user, isDesignate = 0) => {
    const { operating_asset_first_name } = authData
    const { form_name } = form
    let content = null
    if (isDesignate === 1) {
      content = `${operating_asset_first_name} assigned the form '${form_name}' to '${
        user.label
      }' @ ${formatDueDateForTimelineCurrDate()}`
    } else if (isDesignate === 2) {
      content = contactValue.comment
    } else {
      content = `${operating_asset_first_name} removed '${
        user.label
      }' from form '${form_name}' delegation @ ${formatDueDateForTimelineCurrDate()}`
    }
    let timelineParams = buildTimelineEntryParams(content, isDesignate)
    mutateAddTimeLineUpdate(
      { ...timelineParams },
      {
        onSuccess: data => {
          if (data.status === 200) {
            const removedIndex = participantList.findIndex(
              item => item.operating_asset_first_name === user.label
            )
            participantList.splice(removedIndex, 1)
          }
        },
      }
    )
  }

  const handleContactChange = (e, type) => {
    if (type === 'contact') {
      setContactValue({
        ...contactValue,
        selectedUsers: e,
      })
    } else {
      setContactValue({
        ...contactValue,
        comment: e.target.value,
      })
    }
  }

  const validateForm = () => {
    const { selectedUsers, comment } = contactValue
    if (selectedUsers.length && comment.length) {
      return true
    } else {
      return false
    }
  }

  const onAddFormParticipant = () => {
    const { selectedUsers } = contactValue
    const { activity_id } = activity

    mutateAddFormParticipant(
      { form, activity_id, selectedUsers },
      {
        onSuccess: data => {
          if (data === 200) {
            selectedUsers.forEach(user => {
              updateTimelineEntry(user, 1)
              updateTimelineEntry(user, 2)
              setSubmittedView()
            })
            // TODO: refactor ME
            mutateInviteParticipant({
              activity_id,
              activity_type_category_id: 48,
              activity_type_id: 190324,
              content: 'Meeting Users',
              participants: selectedUsers.map(u => {
                const {
                  asset_first_name,
                  contact_asset_type_id,
                  contact_asset_id,
                  contact_first_name,
                  contact_account_id,
                  asset_type_name,
                  contact_department,
                  contact_designation,
                  contact_email_id,
                  contact_location,
                  contact_manager_asset_id,
                  contact_operating_asset_id,
                  contact_operating_asset_name,
                  contact_organization,
                  contact_organization_id,
                  contact_phone_country_code,
                  contact_phone_number,
                  contact_profile_picture,
                  contact_workforce_id,
                } = u.data
                return {
                  access_role_id: 1,
                  account_id: authData.account_id,
                  asset_category_id: 1,
                  asset_first_name,
                  asset_id: contact_asset_id,
                  asset_type_id: authData.asset_type_id,
                  label: contact_operating_asset_name,
                  message_unique_id: getMessageUniqueId(),
                  operating_asset_first_name: contact_operating_asset_name,
                  organization_id: authData.organization_id,
                  value: {
                    contact_account_id,
                    contact_asset_id,
                    contact_asset_type_id,
                    contact_asset_type_name: asset_type_name,
                    contact_department,
                    contact_designation,
                    contact_email_id,
                    contact_first_name,
                    contact_location,
                    contact_manager_asset_first_name: '',
                    contact_manager_asset_id,
                    contact_operating_asset_id,
                    contact_operating_asset_name,
                    contact_organization,
                    contact_organization_id,
                    contact_phone_country_code,
                    contact_phone_number,
                    contact_profile_picture,
                    contact_workforce_id,
                  },
                  workforce_id: authData.workforce_id,
                  workforce_name: authData.workforce_name,
                }
              }),
            })
          }
        },
      }
    )
  }

  useEffect(() => {
    loadParticipants()
    loadOrgContacts()
  }, [])

  return (
    <Box p="30px" className={`form-delegation-container`}>
      {submittedView ? (
        <Box h="200px">
          <Center>
            <Text>Form has been designated successfully!</Text>
          </Center>
        </Box>
      ) : (
        <SimpleGrid minChildWidth="150px" spacing="40px">
          <Box>
            <Text color="black" fontSize="lg">
              Select Participants
            </Text>
            <Box>
              {/*
              FIXME: @shibasis make this working with async select which is chakra based design component
              <AsyncSelect
                  name="contact"
                  components={customAssetSelectFormat}
                  isDisabled={false}
                  placeholder="Search contact"
                  borderRadius="md"
                  size="sm"
                  closeMenuOnSelect={false}
                  isMulti={true}
                  cacheOptions
                  defaultOptions={
                    !!contactList && contactList.length ? contactList : []
                  }
                  //   onChange={onChange}
                  //   value={
                  //     fields[field.field_id] ? fields[field.field_id].field_value : []
                  //   }
                  menuPlacement="bottom"
                /> */}
              <Select
                placeholder="Search contacts"
                isMulti
                options={contactList}
                closeMenuOnSelect={false}
                onChange={e => handleContactChange(e, 'contact')}
                onKeyDown={e => onDebounceSearch(e)}
              />
              <Textarea
                mt="10px"
                placeholder="Enter comment"
                onChange={e => handleContactChange(e, 'comment')}
              />
              <Button
                colorScheme={localStorage.getItem('color')}
                bg={localStorage.getItem('color')}
                onClick={() => {
                  sectionDetailedTrack({
                    category: GA_CATEGORY_DIALOG_BOX,
                    action: 'Delegate Form Modal',
                    label: 'Assign Participant',
                  })
                  onAddFormParticipant()
                }}
                isDisabled={!validateForm()}
              >
                Assign Participant
              </Button>
            </Box>
          </Box>
          {!!participantList && participantList.length ? (
            <Box>
              <Text color="black" fontSize="lg">
                Assigned Participants
              </Text>
              <Box maxH="220px" overflowY="auto">
                {participantList.map(participant => (
                  <Flex p={2} mt={2} bg="gray.100" rounded="md">
                    <Text color="black">
                      {participant.operating_asset_first_name}
                    </Text>
                    <Spacer />
                    <Icon
                      as={MdDelete}
                      color="#48BB78"
                      boxSize="18px"
                      cursor="pointer"
                      onClick={() => {
                        sectionDetailedTrack({
                          category: GA_CATEGORY_DIALOG_BOX,
                          action: 'Delegate Form Modal',
                          label: 'Delete Participant',
                        })
                        removeDesignation(participant)
                      }}
                    />
                  </Flex>
                ))}
              </Box>
            </Box>
          ) : null}
        </SimpleGrid>
      )}
    </Box>
  )
}

export default DelegateFormPopup
