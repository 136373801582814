import {
	Box,
	Collapse,
	HStack,
	Icon,
	Tag,
	Text,
	Center,
	Flex,
	Divider,
	Tooltip,
	useDisclosure,
	VStack,
	Button,
	useToast,
} from '@chakra-ui/react'
import React, { useCallback, useState, useEffect } from 'react'
import { BsCheckCircle, BsCircle, BsXCircle } from 'react-icons/bs'
import { HiOutlineViewGridAdd } from 'react-icons/hi'
import { FaFileAlt } from 'react-icons/fa'
import { IoMdPersonAdd } from 'react-icons/io'
import { BiTrash } from 'react-icons/bi'
import { RiDraftFill, RiEditBoxLine } from 'react-icons/ri'
import {
	getShortUrlId,
	getUrlId,
	toSnakeCase,
	getS3BucketName,
} from '../../../../../utils/common.util'
import Axios from 'axios'
import {
	formatDateTime,
	getAddHoursToCurrentDateUTC,
	getUTCtoIST,
	timelineDateFormat,
} from '../../../../../utils/date.utils'
import moment from 'moment'
// import {
// 	dataLayerTagManager,
// 	GA_CATEGORY_DATA_MANAGEMENT,
// 	sectionDetailedTrack,
// } from '../../../../repository/repo.utils'
import { useLoadActivityParticipant } from '../../../../../hooks/activity.hooks'
import { useAuthContext } from '../../../../../context/auth.context'
import { FiCopy } from 'react-icons/fi'

const FORM_PREFIX = {
	705: 'has been submitted',
	713: 'has been updated',
	726: 'has been resubmitted',
}

const FormListingItem = ({
	f,
	onFormSelection,
	isFormEnable,
	openDelegateFormPopup,
	isCurrentStatus,
	draftDelete = null,
	onOpenRomsView,
	activity,
	onRegerateAlert = () => null,
	isSuperAdmin = false,
	isFromWhatNext = false,
	onOpenNoAccess = () => null,
}) => {
	const { isOpen, onToggle } = useDisclosure()
	const { isOpen: isOpenDraft, onToggle: onToggleDraft } = useDisclosure()
	const { isOpen: expand, onToggle: expandToggle } = useDisclosure()
	const { mutate } = useLoadActivityParticipant()
	const {
		state: { authData },
	} = useAuthContext()
	const toast = useToast({
		duration: 1000,
	})
	const [selectedReviewForms, setReviewForms] = useState({})
	const [email, setEmail] = useState('')
	const [activityType, setActivityType] = useState({})
	let romsConfig =
		!!f.form_config_roms && Object.keys(f.form_config_roms).length
			? JSON.parse(f.form_config_roms)
			: null
	let romsActivity = !!romsConfig
		? !!romsConfig['form_config_roms']
			? romsConfig['form_config_roms'][activity.activity_type_id]
			: romsConfig[activity.activity_type_id]
		: null
	useEffect(() => {
		setReviewForms({
			label: f?.form_name,
			value: f?.form_id,
			data: f,
		})
	}, [f])

	const loadParticipants = async params => {
		let resp = await new Promise((resolve, reject) => {
			mutate(
				{ ...params },
				{
					onSuccess: async data => {
						resolve(data.list)
					},
					onError: async err => reject([]),
				}
			)
		})
		return resp
	}

	if (!isCurrentStatus && !f.isSubmitted) {
		return <></>
	}

	const htmlDecode = content => {
		var rawMarkup = escape(content).replace(/%0A/g, '<br/>')
		return { __html: unescape(rawMarkup) }
	}

	let mandatorFieldList = f?.form_mandatory_field_data
		? JSON.parse(f?.form_mandatory_field_data)
		: []
	let toShowstring = mandatorFieldList.map(val => val.field_name).join(',')

	const { form_flag_enable_data_secrecy = 0 } =
		JSON.parse(f?.data_entity_inline) || {}

	const checkFormAccess = () => {
		if (form_flag_enable_data_secrecy === 1) {
			return f?.submitted_log_asset_id === authData.asset_id || isSuperAdmin
		} else {
			return true
		}
	}

	const checkOwnerOrLead = () => {
		return activity.asset_flag_is_owner || activity.isLead
	}

	const checkOwnerAccess = async (form, issubmit) => {
		let participants = await loadParticipants({
			activity_id: activity?.activity_id,
		})
		let activeOwnersList = (participants || []).filter(
			u => !!u.asset_flag_is_owner
		)
		let enable_acc = activeOwnersList.map(a => a.asset_id)
		let acc =
			form_flag_enable_data_secrecy === 1 && form?.form_submission_type_id !== 2
				? isSuperAdmin || form?.asset_id === authData.asset_id
				: enable_acc.includes(authData.asset_id) ||
				  isSuperAdmin ||
				  form?.asset_id === authData.asset_id ||
				  checkOwnerOrLead()
		if (
			form?.activity_status_flag_enable_date_restriction === 1 &&
			!!form?.activity_status_date_restriction
		) {
			if (
				new Date().setHours(0, 0, 0, 0) <
				new Date(
					new Date(
						form?.activity_status_date_restriction.split(' ').join('T')
					).setDate(
						new Date(
							form?.activity_status_date_restriction.split(' ').join('T')
						).getDate() + 1
					)
				).setHours(0, 0, 0, 0)
			) {
				onFormSelection(form, issubmit, acc, form_flag_enable_data_secrecy)
			} else {
				onOpenNoAccess()
			}
		} else {
			onFormSelection(form, issubmit, acc, form_flag_enable_data_secrecy)
		}
	}

	const checkAccessMultiForms = async (form, issubmit) => {
		let participants = await loadParticipants({
			activity_id: activity?.activity_id,
		})
		let activeOwnersList = (participants || []).filter(
			u => !!u.asset_flag_is_owner
		)
		let enable_acc = activeOwnersList.map(a => a.asset_id)
		let acc =
			form_flag_enable_data_secrecy === 1
				? isSuperAdmin || form?.asset_id === authData.asset_id
				: enable_acc.includes(authData.asset_id) ||
				  isSuperAdmin ||
				  form?.asset_id === authData.asset_id ||
				  checkOwnerOrLead()
		if (
			form?.activity_status_flag_enable_date_restriction === 1 &&
			!!form?.activity_status_date_restriction
		) {
			if (
				new Date().setHours(0, 0, 0, 0) <
				new Date(
					new Date(
						form?.activity_status_date_restriction.split(' ').join('T')
					).setDate(
						new Date(
							form?.activity_status_date_restriction.split(' ').join('T')
						).getDate() + 1
					)
				).setHours(0, 0, 0, 0)
			) {
				onFormSelection(form, issubmit, acc, form_flag_enable_data_secrecy)
			} else {
				onOpenNoAccess()
			}
		} else {
			onFormSelection(form, issubmit, acc, form_flag_enable_data_secrecy)
		}
	}

	let isFormDisabled = true
	if (f?.form_flag_enable_role_restriction) {
		let data =
			typeof f?.form_role_restriction_data === 'string'
				? JSON.parse(f?.form_role_restriction_data)
				: f?.form_role_restriction_data
		isFormDisabled = isSuperAdmin
			? isSuperAdmin
			: !!data.find(val => val.asset_type_id === authData.asset_type_id)
	}
	isFormDisabled = isFormDisabled && (checkFormAccess() || checkOwnerOrLead())
	const createDynamicLink = async url => {
		return await Axios.post(
			'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyAWSBRYMxdKQlCl4TNJymMEmve3kPFsrdI',
			{
				dynamicLinkInfo: {
					domainUriPrefix: 'https://greneos.page.link',
					link: url,
					navigationInfo: {
						enableForcedRedirect: true,
					},
				},
			}
		)
	}
	const createFormUpdateUrlParam = () => {
		return {
			organization_id: authData.organization_id,
			account_id: authData.account_id,
			workforce_id: authData.workforce_id,
			asset_id: authData.asset_id,
			auth_asset_id: authData.asset_id,
			asset_token_auth: authData.asset_token_auth, //"05986bb0-e364-11e8-a1c0-0b6831833754",
			activity_id: activity.activity_id,
			activity_type_category_id: 9,
			activity_stream_type_id: 705,
			form_id: 0,
			form_flag_prefill_enabled: 0,
			activity_type_id: activity.activity_type_id,
			activity_type_id_form: activityType.activity_type_id,
			type: 'approval',
			asset_first_name: authData.asset_first_name,
			asset_phone_number: authData.asset_phone_number,
			operating_asset_first_name: authData.operating_asset_first_name,
			s3BucketName: getS3BucketName(),
		}
	}

	const getFormsToUpdate = async (updatePayload, toUpdate, email) => {
		let forms = {}
		updatePayload.form_id = toUpdate.value
		updatePayload.form_flag_prefill_enabled =
			toUpdate?.data?.form_flag_prefill_enabled
		updatePayload.form_flag_enable_external_link =
			toUpdate?.data?.form_flag_enable_external_link
		let data = await getUrlId(email, updatePayload)
		let url = process.env.REACT_APP_EXT_URL

		return `${url}forms/entry/${btoa(JSON.stringify(data))}`
	}
	const createFormReviewUrl = async (email, form) => {
		const formConfigRoms = JSON.parse(form?.data?.form_config_roms)
		const digitalFormIds = []
		if (formConfigRoms) {
			for (const key in formConfigRoms) {
				const digitalFormId = formConfigRoms[key].digital_form_id
				digitalFormIds.push(digitalFormId)
			}
		}
		const JsonStrReviewCAF = {
			account_id: authData.account_id,
			activity_id: activity.activity_id,
			asset_id: authData.asset_id,
			auth_asset_id: authData.asset_id,
			auth_token: authData.asset_token_auth,
			asset_token_auth: authData.asset_token_auth,
			disabledProperty: true,
			form_id: form.value,
			form_name: form.label,
			form_transaction_id: form.data.form_transaction_id,
			org_id: authData.organization_id,
			workforce_id: authData.workforce_id,
			organization_id: authData.organization_id,
			s3BucketName: getS3BucketName(),
			form_flag_prefill_enabled: form.form_flag_prefill_enabled,
			activity_type_id: activity.activity_type_id,
			parent_status_id: activity?.parent_status_id,
			activity_status_id: activity?.activity_status_id,
			activity_type_category_id: activity?.activity_type_category_id,
			form_flag_enable_external_link:
				form?.data?.form_flag_enable_external_link,
			digital_form_id: digitalFormIds.length > 0 ? digitalFormIds[0] : '',
		}
		let data = await getUrlId(email, JsonStrReviewCAF)
		let url = process.env.REACT_APP_EXT_URL
		return `${url}forms/preview/${btoa(JSON.stringify(data))}`
	}
	const copyUrl = async form => {
		let url = ''
		if (!!form.isSubmitted) {
			url = await createFormReviewUrl(email, selectedReviewForms)
		} else {
			const updatePayload = createFormUpdateUrlParam()
			url = await getFormsToUpdate(updatePayload, selectedReviewForms, email)
		}

		const res = await createDynamicLink(url)
		navigator.clipboard.writeText(res.data.shortLink)
		toast({
			title: 'Link Copied!',
			status: 'success',
		})
	}
	return (
		<Box
			w='full'
			key={f.form_id}
			className={`form-item-${toSnakeCase(f.form_name)} ${
				!!f.isSubmitted ? 'active' : f === 4 ? 'disabled' : 'inactive'
			}`}
		>
			<HStack
				justifyContent='space-between'
				borderRadius='md'
				boxShadow='md'
				bg={
					isFormEnable
						? (f.form_submission_type_id === 1 &&
								!f.isSubmitted &&
								!!f.isDraft) ||
						  !isFormDisabled
							? 'gray.200'
							: ''
						: 'gray.200'
				}
				p={2}
				w='full'
				cursor={
					(!!romsActivity &&
						romsActivity.digital_form_id === f.form_id &&
						!f.isSubmitted) ||
					!isFormDisabled
						? 'not-allowed'
						: 'pointer'
				}
			>
				<Text
					flex={1}
					onClick={() => {
						// sectionDetailedTrack({
						// 	category: GA_CATEGORY_DATA_MANAGEMENT,
						// 	action: 'Form Listing',
						// 	label: 'Form Selection',
						// })
						// dataLayerTagManager('button_click', {
						// 	viewName: 'Data Management',
						// 	buttonName: 'Select Form',
						// })
						if (!isFormDisabled) return
						if (f.form_submission_type_id === 2) {
							checkOwnerAccess(f)
						} else {
							if (
								f.form_submission_type_id === 1 &&
								!f.isSubmitted &&
								!!f.isDraft
							) {
								return
							} else {
								if (
									!!romsActivity &&
									romsActivity.digital_form_id === f.form_id &&
									!f.isSubmitted
								) {
									return
								} else {
									checkOwnerAccess(f, f.isSubmitted)
								}
							}
						}
					}}
					className={`form-item-text-${toSnakeCase(f.form_name || '')}`}
					display={'flex'}
				>
					<Icon
						className={`form-item-circle-icon-${toSnakeCase(
							f.form_name || ''
						)}`}
						as={
							!!f.isSubmitted
								? BsCheckCircle
								: isFormEnable && !!isCurrentStatus
								? f.form_submission_type_id === 1 && f.isDraft
									? BsXCircle
									: BsCircle
								: BsXCircle
						}
						color={
							!!f.isSubmitted
								? localStorage.getItem('color')
								: f === 4
								? 'secondary'
								: ''
						}
						w={5}
						h={5}
						mr={2}
					/>
					{f.form_name || ''}
				</Text>
				{!!f.form_flag_enable_external_link && (
					<Tooltip
						fontSize='sm'
						bg='secondary'
						color='black.700'
						label='Copy Link'
						placement='top'
						hasArrow
					>
						<Box position='relative' display='inline-block'>
							<Icon as={FiCopy} w={4} h={4} onClick={() => copyUrl(f)} />
						</Box>
					</Tooltip>
				)}
				<HStack>
					<Box>
						{/* Origin form */}
						{f.form_submission_type_id === 3 ? (
							<Tooltip
								fontSize='sm'
								bg='secondary'
								color='black.700'
								label='Multi submission'
								aria-label='multi'
							>
								<Tag
									variant='outline'
									colorScheme={localStorage.getItem('color')}
									borderRadius='full'
									boxShadow='sm'
								>
									Origin form
								</Tag>
							</Tooltip>
						) : null}
						{/* multi submission form */}
						{f.form_submission_type_id === 2 ? (
							<Tooltip
								fontSize='sm'
								bg='secondary'
								color='black.700'
								label='Can be submitted multiple times'
								aria-label='multi'
								cursor='pointer'
							>
								<span>
									<Icon
										cursor='pointer'
										as={HiOutlineViewGridAdd}
										w={5}
										h={5}
										onClick={e => {
											// sectionDetailedTrack({
											// 	category: GA_CATEGORY_DATA_MANAGEMENT,
											// 	action: 'Form Listing',
											// 	label: 'Toggle Multi Submission',
											// })
											// dataLayerTagManager('button_click', {
											// 	viewName: 'Data Management',
											// 	buttonName: 'Multi Submission',
											// })
											if (!isFormDisabled) return
											e.stopPropagation()
											if (f.submittedList && f.submittedList.length > 0) {
												onToggle()
											}
										}}
									/>
								</span>
							</Tooltip>
						) : null}

						{/* caf/crf form */}
						{!!f.form_flag_consolidation_enabled &&
						!!romsActivity &&
						f.form_flag_consolidation_enabled === 1 ? (
							<Tooltip
								fontSize='sm'
								bg='secondary'
								color='black.700'
								label={`${romsActivity?.roms_type} View`}
								aria-label='multi'
								cursor='pointer'
							>
								<span>
									{!!f.isSubmitted ? (
										<Button
											variant='solid'
											size='xs'
											colorScheme={localStorage.getItem('color')}
											bg={localStorage.getItem('color')}
											type='submit'
											borderRadius='sm'
											minW='100px'
											onClick={() => {
												// sectionDetailedTrack({
												// 	category: GA_CATEGORY_DATA_MANAGEMENT,
												// 	action: 'Form Listing',
												// 	label: 'View',
												// })
												// dataLayerTagManager('button_click', {
												// 	viewName: 'Data Management',
												// 	buttonName: 'CAF CRF View',
												// })
												onOpenRomsView(f)
											}}
										>
											{romsActivity?.roms_type} View
										</Button>
									) : null}
									{/* <Icon
                    cursor="pointer"
                    as={HiOutlineViewGridAdd}
                    w={5}
                    h={5}
                    onClick={e => {
                      e.stopPropagation();
                      if (f.submittedList && f.submittedList.length > 0) {
                        onToggle();
                      }
                    }}
                  /> */}
								</span>
							</Tooltip>
						) : null}
						{/* {!f.isSubmitted && !!isFormEnable ? (
              <Tooltip
                fontSize="sm"
                bg="secondary"
                color="black.700"
                label="Assign participant"
                aria-label="assign"
                cursor="pointer"
              >
                <span>
                  <Icon
                    mx={2}
                    cursor="pointer"
                    as={IoMdPersonAdd}
                    w={5}
                    h={5}
                    onClick={() => openDelegateFormPopup(f)}
                  />
                </span>
              </Tooltip>
            ) : null} */}
						{!f.isSubmitted && !!f.isDraft ? (
							<Tooltip
								fontSize='sm'
								bg='secondary'
								color='black.700'
								label='Draft Forms'
								aria-label='draft'
								cursor='pointer'
							>
								<span>
									<Icon
										ml={2}
										zIndex={2}
										cursor='pointer'
										as={RiEditBoxLine}
										w={5}
										h={5}
										onClick={e => {
											// sectionDetailedTrack({
											// 	category: GA_CATEGORY_DATA_MANAGEMENT,
											// 	action: 'Form Listing',
											// 	label: 'Draft Forms',
											// })
											// dataLayerTagManager('button_click', {
											// 	viewName: 'Data Management',
											// 	buttonName: 'Draft Forms',
											// })
											e.stopPropagation()
											if (f.draftList && f.draftList.length > 0) {
												onToggleDraft()
											}
										}}
									/>
								</span>
							</Tooltip>
						) : null}
					</Box>
				</HStack>
			</HStack>
			{toShowstring && isFromWhatNext && (
				<Text borderRadius='md' boxShadow='md' p={2}>
					<HStack justifyContent='space-between' alignItems='center' w='full'>
						<Text fontSize='10px' color='brand.800' alignSelf='flex-end'>
							Below are the mandatory fields which are required to submit the
							form
						</Text>
						<Text
							alignSelf='flex-start'
							cursor='pointer'
							fontSize='sm'
							display='inline'
							color='blue.300'
							ml={1}
							onClick={() => {
								expandToggle()
							}}
						>
							{!expand ? 'read more' : 'read less'}
						</Text>
					</HStack>
					<Collapse
						alignSelf='flex-start'
						startingHeight={20}
						in={expand}
						w='100%'
					>
						{toShowstring}
					</Collapse>
				</Text>
			)}
			<Collapse in={isOpen}>
				<VStack w='full' p={2} my={2} maxH='150px' overflowY='scroll'>
					{f.submittedList &&
						(f.submittedList || []).map(d => (
							<HStack
								key={d.form_transaction_id}
								justifyContent='space-between'
								borderRadius='md'
								boxShadow='sm'
								bg={localStorage.getItem('color')}
								p={2}
								mx={4}
								w='full'
								color='white'
								cursor='pointer'
								onClick={e => {
									// sectionDetailedTrack({
									// 	category: GA_CATEGORY_DATA_MANAGEMENT,
									// 	action: 'Form Listing',
									// 	label: 'Form Selection',
									// })
									checkAccessMultiForms(
										{
											...d,
											form_id: d.data_form_id,
											form_name: d.data_form_name,
											form_transaction_id: d.data_form_transaction_id,
										},
										true
									)
								}}
							>
								<HStack>
									{/* <Flex
                    className={`timeline-attachment-message-file-details`}
                    mx={5}
                    alignSelf="flex-start"
                    justifyContent="center"
                    align="center"
                    h="70px"
                    w="70px"
                    bg="white"
                    borderRadius="lg"
                  >
                    <Center>
                      <Icon as={FaFileAlt} color="brand.800" h="3rem" w="3rem" />
                    </Center>
                  </Flex> */}
									<VStack
										wordBreak='break-all'
										alignSelf='flex-start'
										alignItems='flex-start'
										flex={1}
										pr={10}
									>
										<Text fontSize='sm' fontWeight='600' color='white'>{`${
											d.data_form_name
										} ${FORM_PREFIX[d.timeline_stream_type_id]}`}</Text>

										{/* <Text
                      // textAlign="left"
                      color='white'
                      fontSize="12px"
                      dangerouslySetInnerHTML={htmlDecode(JSON.parse(d.data_entity_inline)?.content)}
                    /> */}
										{JSON.parse(d.data_entity_inline)
											?.form_field_preview_enabled &&
										JSON.parse(d.data_entity_inline)?.form_field_preview_enabled
											?.length ? (
											<VStack alignItems='flex-start' w='full'>
												<Divider />
												{JSON.parse(
													d.data_entity_inline
												).form_field_preview_enabled.map((el, i) => {
													var hours = 0
													var minutes = 0
													hours =
														!!el.field_value && !!el.field_value.duration
															? Math.floor(Number(el.field_value.duration) / 60)
															: 0
													minutes =
														!!el.field_value && !!el.field_value.duration
															? Number(el.field_value.duration) % 60
															: 0
													let f_value =
														typeof el.field_value === 'object'
															? JSON.stringify(el.field_value)
															: el.field_value
													let text = escape(f_value).replace(/%5Ct/g, ' ')
													el['field_value'] =
														!!el.field_value && !!el.field_value.duration
															? el.field_value
															: unescape(text)
													return (
														<Text color='black' fontWeight='500' key={i}>
															{el.field_name} :{' '}
															{!!el.field_value && !!el.field_value.duration ? (
																<>
																	<Text color='black' fontWeight='400'>
																		{`Start Datetime: `}
																		<Text
																			as='span'
																			fontWeight='400'
																			color='white'
																		>
																			{`${moment(
																				el.field_value.start_date_time
																			).format('DD/MM/YYYY hh:mm A')}`}
																		</Text>
																	</Text>
																	<Text color='black' fontWeight='400'>
																		{`Duration: `}
																		<Text
																			as='span'
																			fontWeight='400'
																			color='white'
																		>
																			{`${hours} Hr ${minutes} Min`}
																		</Text>
																	</Text>
																	<Text color='black' fontWeight='400'>
																		{`End Datetime: `}
																		<Text
																			as='span'
																			fontWeight='400'
																			color='white'
																		>
																			{`${moment(
																				el.field_value.end_date_time
																			).format('DD/MM/YYYY hh:mm A')}`}
																		</Text>
																	</Text>
																</>
															) : (
																<Text
																	as='span'
																	fontWeight='400'
																	color='white'
																	dangerouslySetInnerHTML={htmlDecode(
																		el.field_value
																	)}
																/>
															)}
														</Text>
													)
												})}
											</VStack>
										) : null}
									</VStack>
								</HStack>
								<Text
									className={`timeline-attachment-message-text-time-log`}
									fontSize='13px'
									color='white'
									alignSelf='flex-start'
									mb={0}
								>
									{timelineDateFormat(d.log_datetime)}
									<Text fontSize='15px' color='white'>
										{d.operating_asset_first_name || ''}
									</Text>
								</Text>
							</HStack>
						))}
				</VStack>
			</Collapse>
			<Collapse in={isOpenDraft}>
				<VStack w='full' p={2} my={2} maxH='150px' overflowY='scroll'>
					{f.draftList &&
						(f.draftList || []).map(d => (
							<HStack
								key={d.form_transaction_id}
								justifyContent='space-between'
								borderRadius='md'
								boxShadow='sm'
								bg={localStorage.getItem('color')}
								p={2}
								mx={4}
								w='full'
							>
								<Text
									cursor='pointer'
									color='white'
									onClick={() => {
										// sectionDetailedTrack({
										// 	category: GA_CATEGORY_DATA_MANAGEMENT,
										// 	action: 'Form Listing',
										// 	label: 'Form Selection',
										// })
										checkOwnerAccess({
											...d,
											isDraft: true,
											draftFormTransId: d.form_transaction_id,
											activity_type_flag_enable_draft:
												f.activity_type_flag_enable_draft,
										})
									}}
								>
									<Icon as={RiEditBoxLine} w={5} h={5} mr={2} />
									{d.operating_asset_first_name || ''}
								</Text>
								<Tooltip
									fontSize='sm'
									bg='secondary'
									color='black.700'
									label='Delete Draft'
									aria-label='delete'
									cursor='pointer'
								>
									<span>
										<Icon
											ml={2}
											zIndex={2}
											color='white'
											cursor='pointer'
											as={BiTrash}
											w={5}
											h={5}
											onClick={e => {
												// sectionDetailedTrack({
												// 	category: GA_CATEGORY_DATA_MANAGEMENT,
												// 	action: 'Form Listing',
												// 	label: 'Draft Delete',
												// })
												e.stopPropagation()
												draftDelete(d)
											}}
										/>
									</span>
								</Tooltip>
							</HStack>
						))}
				</VStack>
			</Collapse>
		</Box>
	)
}

export default FormListingItem
